
.detail {

    &__h1 {
        font-weight: 700;
        font-size: 16px;
    }

    &__block {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin: 8px 0;
    }

    &__h, &__price {
        font-weight: 700;
    }

    &__options {
        display: flex;
        gap: 5px;
    }
}