.dsubmenu {
    position: absolute;
    background-color: #f9f9f9;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px 15px;
    width: 200px;
    
    &__fadein {
        transition: opacity 0.5s;
        opacity: 1;
    }

    &__hide {
        opacity: 0;
    }

    &__item {
        @extend .flex-row;
        justify-content: flex-start;
        gap: 10px;
        margin: 5px 0;
        padding: 8px 10px;
        border-radius: 5px;
        cursor: pointer;
    }

    &__item:hover {
        background-color: #B7B8BA;
    }
}

// .dsubmenu::after {
//     position: absolute;
//     top: -5px;
//     right: 40px;
//     width: 10px;
//     height: 10px;
//     background-color: #f9f9f9;
//     content: '';
//     transform: rotate(45deg);
//     border-left: 1px solid black;
//     border-top: 1px solid black;
// }