
.detail {

    &__logo-container {
        // max-width: 400px;
    }

    &__logo {
        height: 250px;
        width: 100%;
        object-fit: cover;
        border-bottom: 1px solid black;
    }
    
    &__item {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin: 25px 10px;
    }

    &__subheading {
        font-weight: 600;
        font-size: 14px;
    }

    &__option-container {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
        padding: 10px 0px;
    }

    &__option {
        padding: 10px;
        background: #f9f9f9;
        border-radius: 5px;
        border: 0.1px solid grey;
    }

    &__tel {
        display: flex;
        gap: 5px;
    }

    &__imgviewer {
        display: none;
    }

    &__video {
        width: 100%;
        height: 300px;
    }
}

.form__btns {
    margin: 40px auto;
}