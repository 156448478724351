.form {
    background: white;
    border-radius: 10px;
    padding: 15px 8px;
    // height: 1104px;
    // overflow-y: scroll;
  
    &__headings {
      color: white;
      padding: 15px 0;
    }
  
    &__h1 {
      padding: 10px 0;
    }
  
    &__h {
      margin-bottom: 10px;
      color: grey;
      font-weight: bold;
    }
  
    &__image {
      text-align: center;
    }
  
    &__img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  
    &__item {
      @extend .flex-row;
      align-items: flex-start;
      padding: 5px 0;
    }
  
    &__input {
      width: 70%;
      padding: 10px 5px;
      border: 1px solid $base-color;
      border-radius: 5px;
    }
  
    &__input:focus {
      outline: none;
      box-shadow: 0 0 3px 2px #0000FF;
    }
  
    &__select {
      padding: 10px 5px;
    }
  
    &__btns {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin: 15px auto;
    }
  
    &__error {
      color: red;
      font-size: small;
      text-align: right;
    }
  
    &__disabled {
      background: grey;
      pointer-events: none;
      cursor: pointer;
    }
  }

.form__label {
  position: relative;
  display: flex;
  gap: 10px;
}

.form__tooltip-text {
    display: none;
    position: absolute;
    bottom: 20px;
    background: #f9f9f9;
    border-radius: 5px;
    border: 1px solid black;
    padding: 10px;
  }

.form__tooltip-icon:hover .form__tooltip-text {
    display: block;
  }