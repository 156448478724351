
strong {
    color: #555;
    line-height: 1.7;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1rem;
    font-weight: bolder;
}

h1 {
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1.2;
    font-size: 1.5rem;
}

h4 {
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 1rem;
    line-height: 1.2;
}

h3 {
    font-size: 1.125rem;
    margin-top: 25px;
    font-weight: 700;
    margin-bottom: 20px;
}

h2 {
    font-size: 1.25rem;
    margin-top: 25px;
    font-weight: 700;
    margin-bottom: 20px;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
}