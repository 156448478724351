
@media screen and (min-width: 1000px) {
  // services listing view
  .profiles {

    &__list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 15px;
    }
  }

  .login {
    &__content {
      width: 30%;
    }
  }

  // service
  // .service {
  //   width: 100%;
  //   // height: 100%;
  //   height: 500px;
  //   margin: 0;

  //   &__container {
  //     display: flex;
  //     flex-direction: column;
  //     width: 100%;
  //     padding: 0;
  //   }

  //   &__image {
  //     margin-top: 10px;
  //   }

  //   &__pic {
  //     width: 200px;
  //     border: 1px solid rgb(161, 157, 157);
  //     border-radius: 50%;
  //     height: 200px;
  //     object-fit: contain;
  //   }

  //   &__content {
  //     flex-direction: column;
  //     align-items: stretch;
  //     width: 90%;
  //   }

  //   &__div {
  //     display: flex;
  //     flex-direction: row;
  //     justify-content: space-between;
  //     margin: 15px auto;
  //   }

  //   &__title {
  //     height: 60px;
  //     margin: 15px auto;
  //   }

  //   &__icon {
  //     display: flex;
  //     flex-direction: row;
  //     align-items:center;
  //     justify-content: center;
  //     border: 2px solid grey;
  //     margin: 15px 0;
  //     border-radius: 5px;
  //     padding: 10px 0;
  //     width: 100%;
  //   }

  //   &__note {
  //     padding: 0 5px;
  //     display: inline-block;
  //     color: black;
  //   }

  // }

  .footer {
    justify-content: center;
    align-items: flex-start;
  }

  .footer * {
    align-self: flex-start;
  }

  .news, .language, .socials {
    margin-top: 40px;
  }

  .news {
    align-self: flex-start;

    &__p {
      margin-top: 8px;
    }

    &__btn {
      padding: 5px;
    }

    &__input {
      height: 35px;
    }
  }


  .copy-right {
    grid-column: 1 / span 5;
    text-align: center;
  }

  .dashboard {
    &__form {
      height: calc(100vh - 40px) !important;
      overflow-y: scroll;
     }
  }

}

@media screen and (min-width: 750px) {
  .services__filters {
    display: block;
    background: #F7F7F7;
    width: 320px;
  }

  .services__list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      align-items: flex-start;
      width: 100%;
      gap: 20px;
      height: 80vh;
      overflow-y: scroll;
  }

}

