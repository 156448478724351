
.fmodal {
    font-family: arial;
  }
  
  .fmodal {
    position: fixed;
    text-align: left;
    z-index: 1040;
    top: 0;
    left: 0;
    height: 100vh;
    overflow: scroll;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }

  .fbackdrop {
    position: fixed;
    z-index: 1040;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: 0.5;
  }