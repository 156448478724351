
.services {

  &__container {
    background: linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.5)), url("./images/service.jpg") center/cover;
  }

  &__block {
    padding: 15px 0;
    text-align: left;
  }

  &__headings {
    color: white;
    padding: 50px 0;
  }
}

.ra {
  &__container {
    background: linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.5)), url("./images/merc.jpeg") center/cover;
  }
}

.rdsd-service {
  margin: 15px 0;
  border: 1px solid grey;
  padding: 15px 10px;
  border-radius: 7px;
}

.form {

  &__profile {
    text-align: center;
  }

  &__image {
    margin: 15px auto;
  }

  &__img {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }

  &__file {
    padding: 10px 0;
  }

  &__address {
    width: 70%;
  }

  &__address-block {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 2px;
    margin-bottom: 5px;
  }

  &__addr {
    width: 100%;
    padding: 10px 5px;
    border-radius: 5px;
  }

  &__banks {
    width: 86%;
    padding: 10px 5px;
    border-radius: 5px;
  }

  &__addr:focus {
    outline: none;
    box-shadow: 0 0 3px 2px #0000FF;
  }

  &__service-block {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 70%;
    justify-content: space-between;
    align-items: center;
  }

  &__tag {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $base-color;
    width: 100%;
    padding: 10px 5px;
  }

  &__tag:focus {
    outline: none;

  }

  &__tag-i {
    height: 15px;
    cursor: pointer;
  }


.tag {
    position: relative;
    display: inline-block;
    margin: 2px 5px;

    &__i {
      position: absolute;
      right: 4px;
      top: 4px;
      cursor: pointer;
    }
  }

  &__tag:focus {
    box-shadow: 0 4px 2px -2px $base-color;
  }
}

.service-options {

  height: calc(100vh - 40px);
  width: 80%;
  margin: auto;
  position: relative;

  &__container {
    position: relative;
  }

  &__block{
    height: 50%;
    display: grid;
  }

  &__link {
    display: inline-block;
    height: 100px;
    color: black;
    border-bottom: 1px solid black;
  }

  &__link:hover {
    transform: translateX(-20);
  }

  &__item {
    height: 100%;
    display: grid;
    align-items: center;
  }

  &__text {
    font-size: 35px;
  }

  &__grp {
    @extend .flex-row;
  }
}


