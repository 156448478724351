
.tower {
    background-color: #f9f9f9;
    padding: 15px 10px;
    border-radius: 8px;
    margin: 15px 0;

    &__container {
        @extend .flex-row;
        margin-bottom: 15px;
    }

    &__row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px
    }

    &__item {
        @extend .flex-row;
        justify-content: flex-start;
        gap: 10px;
        margin-top: 10px;
    }

    &__actions {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    &__delete {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        padding-left: 10px;
    }

    &__update {
        height: auto;
        margin-top: 15px;
    }

    &__fields {
        padding: 0 10px;
    }

    &__input {
        padding: 10px 8px;
        border: 1px solid black;
        border-radius: 5px;
    }

    &__btns {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 10px auto;
    }

    &__progress {
        height: 80px;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center
    }

    &__success {
        @extend .flex-row;
        justify-content: center;
        gap: 5px;
    }

    &__success-text {
        font-size: 12px;
    }
}