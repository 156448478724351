
.submenu {
  margin: 40px auto;
  width: 80%;

  &__links {
    display: flex;
    gap: 10px;
  }

  &__images {
    position: relative;
    height: 400px;
  }

  &__h {
    padding: 20px 0;
  }

  &__p {
    padding: 10px 0;
    cursor: pointer;
  }

  &__image {
    margin: 10px auto;
    position: absolute;
    width: 100%;
  }

  &__img {
    width: 100%;
    height: 360px;
    object-fit: cover;
  }

  &__active {
    border-bottom: 5px solid rgba(28, 34, 56, 1);
  }

  &__active-img {
    z-index: 2;
  }
}

.app {
  
  &__text {
    margin-bottom: 10px;
  }

  &__links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
  }

  &__link {
    display: inline-block;
  }

  &__android {
  background: url('./images/get-it-on-google-play-badge.webp') center/cover;
  height: 40px;
  width: 200px;
  border-radius: 5px;
  cursor: pointer;
  }

  &__ios {
    background: url('./images/app-store.webp') center/cover;
    height: 40px;
    width: 200px;
    border-radius: 5px;
    cursor: pointer;
    }
}
//  
// .header {
//   // margin: auto;
//   // background: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.5)), url('./images/race-car-mobi.webp') center/cover;
//   // height: 90vh;
//   // width: 100vw;

//   // &__card {
//   //   text-align: center;
//   // }

//   // &__h1 {
//   //   font-family: 'Poppins', sans-serif;
//   //   font-size: 20px;
//   //   font-weight: 100;
//   //   color: white;
//   //   background: transparent;
//   //   position: absolute;
//   //   top: 50vh;
//   //   text-align: center;
//   //   width: 90%;
//   // }
// }

.card {
  margin: 40px auto;
  width: 80%;
  &__text {
    text-align: center;
  }

  &__container {
    padding: 0 20px;
    // background: #F6F3F3;
  }

  &__h {
    font-weight: bolder;
  }

  &__p {
    line-height: 1.4;
  }

  &__img {
    width: 100%;
  }
}

.why {
  margin: 40px auto;

  &__h {
    text-align: center;
    font-weight: 600;
  }
}

.signup {
  width: 95%;
  margin: 70px auto;

  &__element {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $base-color;
    height: 50px;
    padding: 40px 0;
  }

  &__i {
    color: $base-color;
  }

  &__h2 {
    color: $base-color;
  }

}
