.stats {

    &__total {
        margin: 15px 0;
        padding: 15px 10px;
        border-radius: 8px;
        background-color: #f9f9f9;
    }

    &__block {
        margin: 25px 0;
    }

    &__blockt {
        font-size: 25px;
        color: #8d8f94;
        margin-top: 30px;
    }

    &__option {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 15px 0;
        padding: 15px 10px;
        border-radius: 8px;
        background-color: #f9f9f9;
        width: 90%;
        box-shadow: 2px 2px 8px 8px rgba(77, 76, 76, 0);
    }

    &__row {
        @extend .flex-row;
        justify-content: space-around;
        gap: 10px;
    }

    &__text {
        font-weight: 100;
    }

    &__value {
        font-size: 40px;
    }

    &__pending {
        color: rgb(238, 144, 13);
    }

    &__inprogress {
        color: blue;
    }

    &__completed, &__accept {
        color: green;
    }

    &__reject {
        color: red;
    }


    &__trow {
        display: grid;
        grid-template-columns: 3fr 3fr;
        margin: 10px auto;
        padding: 10px 5px;
        border-radius: 8px;
        background-color: #f9f9f9;
    }

    &__trow-mod {
        display: grid;
        grid-template-columns: 1fr 3fr;
        margin: 10px auto;
        padding: 10px 5px;
        border-radius: 8px;
        background-color: #f9f9f9;
    }

    &__h {
        background-color: transparent;
        margin-bottom: 15px;
    }

    &__values {
        @extend .flex-row;
        justify-content: space-around;
    }

    &__title {
        font-weight: 100;
    }

    &__reviews {
        @extend .flex-row;
        justify-content: flex-start;
        gap: 60px;
        margin: 30px auto;
    }

    &__rv {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }

    &__rn {
        font-size: 45px;
        font-weight: 800;
    }

    &__rt {
        color: rgb(157, 153, 153);
        font-weight: 100;
    }

    &__rl {
        @extend .flex-row;
        justify-content: flex-start;
        gap: 20px;
        margin-bottom: 10px;
        cursor: pointer;
    }

    &__meters {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__meter {
        @extend .flex-row;
        justify-content: space-between;
        gap: 20px;
    }

    &__mt {
        color: #B7B8BA;
        font-weight: 100;
    }
}