
.filter {
    padding: 20px 20px 0;

    &__title {
        font-weight: 600;
        font-size: 14px;
    }

    &__section {
        padding: 15px 0;
    }

    &__heading {
        font-weight: 500;
        font-size: 13px;
        margin-bottom: 6px;
    }

    &__selection-container {  
        display: flex;
        flex-direction: column;
    }

    &__selection {
        color: #707070;
        margin-bottom: 4px;
        padding-bottom: 1px;
        cursor: pointer;
        font-weight: 400;
        font-size: 13px;
    }

    &__selection-selected {
        font-weight: 600;
    }

    &__label {
        color: #707070;
        font-size: 13px;
        font-weight: 400;
    }

    &__btns-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        width: 100%;
        margin-top: 50px;
    }
}

@media screen and (max-width: 1130px){
    .filter__modal {
        padding: 20px;
    }
}



.slider {
    max-width: 150px;
    margin-top: 8px;
}

.slider-track {
    top: 4px;
      height: 2px;
      background: grey;
}

.slider-track.slider-track-0 {
    background: #76C3D4;
}

.slider-thumb {
    cursor: pointer;
      background: #76C3D4;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      outline: none;
}

.slider-thumb:hover {
    box-shadow: 0 0 0 8px rgb(123, 123, 226);
}