
.prompt {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    background: white;
    width: 100%;
    height: 100%;

    &__container {
        background: white;
        width: 350px;
        height: 160px;
        border-radius: 8px;
        position: relative;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    }

    &__input {
        border-radius: 4px;
        width: 150px;
        height: 30px;
        background: #f9f9f9;
        transition: 0.3s all;
    }

    &__txt-input:focus {
        box-shadow: 1px 4px 20px 1px rgba(0, 0, 0, 0.05);
    }

    &__txt-input {
        padding: 7px;
        border-radius: 5px;
        border : none;
        background-color: rgba(255,255,255,0.3);
    }

    &__btns {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    &__close {
        position: absolute;
        top: 5px;
        right: 5px;
    }
}