
.footer {
  padding: 15px 0;
  background: #1C2238;

  &__h2, &__p {
    margin: 20px auto;
    color: white;
    cursor: pointer;
  }

  &__p_i {
    cursor: default;
    margin: 20px auto;
  }

  &__p_it {
    cursor: pointer;
    margin: 20px auto;
    font-weight: bold;
  }

  
}


.socials {
  &__icons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  }
  
  &__i {
  font-size: 1em;
  cursor: pointer;
  }
}
