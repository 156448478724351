
.reviews {

    padding: 20px 5%;

    &__header {
        margin-bottom: 30px;
    }

    &__list-container {
        height: 65vh;
        overflow-y: scroll;
    }
}