
$base-color: rgba(28, 34, 56, 1);
$bg-light: #f9f9f9;
$base-radius: 8px;

* {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 12px;
}

::-webkit-scrollbar {
    display: none;
  }

// a {
//   text-decoration: none;
//   color: white;
// }

// article {
//   width: 95%;
//   margin: auto;
// }

