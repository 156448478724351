// password strength meter
.progress {
    display: flex;
    gap: 1px;
    width: 160px;
    height: 5px;
  
    &__weak {
      width: 25%;
      background: red;
    }
  
    &__fair {
      width: 25%;
      background: orange;
    }
  
    &__good {
      width: 25%;
      background: yellowgreen;
    }
  
    &__strong {
      width: 25%;
      background: green;
    }
  }
  
  // signup page
.signup {

    &__root {
      background-color: #1C2238;
      width: 100%;
      padding: 0 10px;
    }

    &__container {
      height: 95vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    &__headings {
      color: white;
      padding: 35px 0;
      text-align: center;
  }

  &-form {
    border-radius: 10px;
  }

  &__h1 {
      padding: 10px 0;
      font-weight: 600;
      font-size: 20px;
  }

  &__p {
    color: black;
  }

  &__h1, &__p {
    color: white;
  }
}

@media screen and (max-width: 500px){
  .signup {
    &__root {
      height: 600px;
    }
  }
}