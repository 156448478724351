
.fwimage {
    display: none;
    
    // &__feature {
    //   margin-bottom: 60px;
    //   border-bottom: 1px solid grey;
    //   padding-bottom: 40px;
    //   overflow: hidden;
    //   text-align: center;
    // }
  
    // &__img  {
    // width: 100%;
    // object-fit: contain;
    // transition: transform 0.2s ease-in;
    // margin-bottom: 20px;
    // max-width: 450px;
    // }

    // &__p {
    //   margin-bottom: 20px;
    // }
}
  
  
.fwicon {
    margin: 20px auto 40px ;

    &__feature {
        justify-content: center;
        margin: 15px auto;
    }

    &__i {
        color: $base-color;
        text-align: center;
        width: 100%;
    }

    &__p {
        text-align: center;
        padding: 15px;
    }
}

.info {
    padding: 30px 0;
    
    &__features {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      align-items: flex-start;
      width: 90%;
      margin: 0 auto 50px;
    }
  
    &__feature {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 90%;
      margin: 10px auto;
    }
  
    &__h {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      padding: 10px 0;
    }
  
    &__p {
      width: 100%;
      text-align: center;
    }
  
    &__i {
      color: $base-color;
      text-align: center;
    }
  }