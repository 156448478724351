.step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    padding: 10px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
}

.stepper-container {
    display: flex;
    flex-direction: column;
    position: sticky;
    left: 0;
    top: 41px;
    background: white;
    z-index: 2;
}

.stepper-header {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
}

.stepper-body {
    padding: 0px;
}

.stepper-header-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    &::after {
        position: absolute;
        content: '';
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 17px;
        left: 50%;
        z-index: 2;
    }

    &.completed {
        .step-counter {
            background-color: #4bb543;
        }
        &::before {
            position: absolute;
            content: '';
            border-bottom: 2px solid #4bb543;
            width: 100%;
            top: 17px;
            left: -50%;
            z-index: 3;
        }
    }

    &:first-child {
        &::before {
            content: none;
        }
    }

    &:last-child {
        &::after {
            content: none;
        }
    }
    
}

@media (max-width: 768px) {
    .stepper-header-item {
        font-size: 12px;
    }
}