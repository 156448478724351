.dchat {
    display: grid;
    grid-template-columns: 1.5fr 3fr;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    background-color: $bg-light;
    border-radius: 15px;
    padding: 10px;
  
    &__profiles {
      height: 70vh;
      overflow: scroll;
      border-radius: 15px;
      background: white;
    }

    &__heading {
        margin-bottom: 20px;
    }

    &__container {
        padding: 20px;
    }
  }