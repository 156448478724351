.footer {
  display: grid;
  margin: auto;
  border-top: 1px solid grey;
  color: white;
}


.div {
  width: 95%;
  margin: 0 auto;
}

#map { /* The id of the container you passed to createMap */
  height: 40vh;
  margin: auto;
}

.dashboard__sidebar > div{
  margin-left: 20px;
}