.profile {
    @extend .flex-row;
    justify-content: space-between;
    padding: 10px;

    &__info {
        @extend .flex-row;
        gap: 10px;
        width: 100%;
    }

    &__email {
        font-size: medium;
        max-width: 85%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__img {
        border-radius: 50%;
        height: 40px;
        width: 40px;
        object-fit: fill;
    }

    &__lm {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 3px;
        font-size: small;
        padding-top: 3px;
    }

    &__active {
        background: rgb(233, 230, 230);
    }

    &__skeleton {
        width: 100%;
    }
}

// opened view
.open-link {
    color: grey;
  }

  //actions
.action {

    &__btns {
        padding-bottom: 20px;
    }

    &__btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 20px 40px;
        margin: 10px auto;
        background-color: #f9f9f9;
        border-radius: 5px;
    }

    &__delete {
        background-color: rgb(255, 238, 237);
    }
}

.upload-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    margin: 10px auto;
}

.upload-block {
    margin-top: 20px;
}

.upload-inputs-hidden {
    display: none;
}

.dtitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.dboard {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    margin-bottom: 10px;

    &__text {
        font-size: 30px;
    }

    &__container {
        height: 90vh;
        overflow: scroll;
        padding-bottom: 20px;
    }

    &__header {
        text-align: center;
    }

    &__btn {
        display: flex;
        justify-content: flex-end;
    }
    
    &__progress-container {
        height: 60px;
        overflow: hidden;
    }

    &__bullet {
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 5px 0;
    }

    &__heading {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    

}

.dinit {
    height: 70vh;
    border-radius: 15px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

//choose profile type screen
.profile-t {

    &__options {
    display: flex;
    flex-direction: column;
    }

    &__option {
        height: 30%;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__type {
        cursor: pointer;
    }  
}

