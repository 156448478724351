.booking {

  &__create {
    margin-bottom: 50px;
  }

  &__header {
    padding: 10px 20px;
    text-align: center;
  }

  &__image {
    width: 100%;
    margin-top: 50px;
    display: none;
  }

  &__img {
    width: 100%;
  }

  &__form {
    padding: 10px;
    border-radius: 10px;
  }

  &__block {
    margin: 25px 0;
  }

  &__form-item {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
  }
    
  &__input {
    padding: 10px 5px;
    border: 1px solid #1c2238;
    border-radius: 5px;
    margin: 4px 0;
  }

  &__selection {
    margin: 8px 0;
  }

  &__radio-option {
    display: flex;
    gap: 10px;
    margin: 6px 0;
  }

  &__select {
    padding: 10px;
    margin: 8px 0;
  }

  &__section {
    font-weight: bold;
    font-size: 14px;
  }

  }