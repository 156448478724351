.booking {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid blue;
    border-radius: 5px;
  
    &__container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  
    &__data {
      width: 90%;
    }
  
    &__row {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin: 15px 0;
    }
  
    &__details {
      @extend .flex-row;
      justify-content: space-between;
      background-color: #f9f9f9;
      padding: 15px 10px;
      border-radius: 8px;
    }
  
    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    &__actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      width: 10%;
    }
  
    &__notif {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;    
    }
  }