.dashboard {
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: flex-start;
    gap: 50px;
    width: 98%;
    margin-top: 10px;

    &__notif {
        height: 95vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    &__link {
        color: blue;
        cursor: pointer;
    }

    &__sidebar {
        height: 80vh;
        overflow: scroll;
    }

    &__option {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        padding: 10px;
        margin: 5px;
        border-radius: 8px;
        cursor: pointer;
    }

    &__active {
        background-color: #f9f9f9;
    }

    &__option-header {
        font-size: large;
        margin-bottom: 10px;;
    }

    &__option-text {
        font-weight: 300;
    }

    &__logo {
        cursor: pointer;
        font-family: "Poppins", sans-serif;
        font-size: 30px;
        padding-top: 10px;
    }

    &__user {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    &__title {
        font-size: 25px;
        padding: 15px 0 25px 0;
    }

    &__profile {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        // margin: 20px auto;
    }

    &__img {
        width: 40px;
        height: 40px;
        object-fit: contain;
        border-radius: 50%;
    }

    &__item {
        padding: 5px;
        border-bottom: 1px solid #B7B8BA;
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        border-left: 5px solid $bg-light;
    }

    &__heading {
        display: flex;
        align-items: center;
        gap: 10px;
        padding-left: 10px;
    }

    &__h {
        font-size: large;
    }

    &__links-container {
        transition: height 0.2s ease-in-out;
    }

    &__links {
        margin-left: 35px;
    }

    &__link {
        padding: 5px 0;
    }

    &__top {
        @extend .flex-row;
        margin: 20px auto;
    }

    &__body {
        position: relative;
    }

    &__view {
        margin-top: 20px;
    }

    &__right {
        background: $bg-light;
        border-radius: $base-radius;
    }

    &__rblock {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    &__ppic {
        width: 200px;
        height: 200px;
        object-fit: contain;
        border-radius: 10px;
    }

    &__text {
        margin: 15px auto;
    }

    &__btns {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin: 10px auto;
    }

    &__skeleton-active {
        margin-top: 50px;
    }
}