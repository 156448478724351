
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.modal {
  position: absolute;
  top: 55px;
  background: #f9f9f9;
  color: green;
  border-radius: 5px;
  width: 400px;
  padding: 10px;
  text-align: center;
  margin: auto;

  &__wrap {
    display: flex;
    justify-content: center;
  }

  &__p {
    font-weight: bold;
  }

  &__link {
    cursor: pointer;
    font-size: small;
    color: $base-color;
  }

  &__warn {
    color: red;
    width: 300px;
  }
}

.imodal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;

  &__overlay {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(49,49,49,0.6);
    height: 60vh;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f9f9f9;
    padding: 20px 30px;
    width: 300px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  }

  &__input {
    padding: 8px 5px;
    margin: 15px auto;
    width: 168px;
    border-radius: 5px;
  }

  &__btns {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
}

// spinner
.ring {
  padding: 25px 0 75px;

  &__text {
    text-align: center;
    color: black;
  }
}

// file uploads
.uploads {
  margin-top: 20px;

  &__images {
    position: relative;
    margin-bottom: 10px;
  }

  &__container:hover &__img {
    opacity: 0.2;
  }

  &__container:hover &__close {
    opacity: 1;
  }

  &__file-background {
    background: #d5d2d2;
  }

  &__close {
    position: absolute;
    top: 0px;
    right: 0px;
    opacity: 0;
    transition: .5s ease;
  }

  &__img {
    width: 200px;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
    opacity: 1;
    transition: .5s ease;
  }

  &__imgsblock {
    display: flex;
    flex-direction: row;
    gap: 15px;
    flex-wrap: wrap;
  }

  &__input {
    padding: 10px 0;
  }

  &__video {
    position: relative;
  }

  &__vid {
    width: 100%;
    height: 50vh;
    opacity: 1;
    transition: .5s ease;
  }

  &__vid-close {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    transition: .5s ease;
  }

  &__video:hover &__vid {
    opacity: 0.2;
  }

  &__video:hover &__vid-close {
    opacity: 1;
  }
}

// label
.label {
  cursor: pointer;
}

// image viewer
.viewer {
  &__open {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }

  &__tiles {
    display: flex;
    overflow: scroll;
  }

  &__tile {
    width: 100px;
    height: 60px;
    cursor: pointer;
    object-fit: cover;
    margin: 5px;
  }

  &__active {
    border: 1px solid red;
  }

  &__first {
    margin-left: 0;
  }
}

// error class
.error {
  text-align: center;
  color: red;
}

// loading spinner
.loading {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__text {
    margin-top: 15px;
  }
}

.hide {
  display: none;
}

// review
.review {
  display: flex;
  flex-direction: column;
  padding: 10px 8px;
  gap: 3px;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin: 15px auto;

  &__text {
    font-weight: 100;
    color: gray;
  }
}

.captcha {
  margin-top: 30px;
}

// static slider
.static-imgslider {
  
  &__img-container {
    // max-width: 400px;
  }

  &__img {
    object-fit: cover;
    width: 90%;
  }
}

.dropdown {
    position: absolute;
    top: 39px;
    left: 0;
    height: 100vh;
    background: rgba(0,0,0,0.9);
    width: 100%;
    padding: 5%;

    &__link {
      margin: 15px 0;
      font-size: 20px;
      cursor: pointer;
      text-align: center;
      color: white;
    }
}


@import "./_nav";
@import "./form";
@import "./features";
@import "./chat";
@import "./prompt";
@import "./_footer";
@import "./filters";
@import "./service";
@import "./modal";
@import "./reviews";
@import "./stepper";

