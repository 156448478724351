
.services {
    padding: 0 5%;

    &__wrapper {
        display: flex;
        gap: 100px;
    }

    &__filters {
        display: none;
    }

    &__content {
        width: 100%;
    }

    &__list-container {   
        height: 80vh;
        // overflow-y: scroll;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        // align-items: flex-start;
        // width: 100%;
        // gap: 20px;
        // height: 80vh;
        overflow-y: scroll;
        // justify-items: center;
    }

    &__vlist {
        height: 100%;
        width: 100%;
    }
}