.login {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  
    &__overlay {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: rgba(49,49,49,0.6);
      height: 100%;
    }
  
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #f9f9f9;
      padding: 20px 30px;
      width: 90%;
      border-radius: 10px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    }
  
    &__input {
      padding: 8px 5px;
      margin: 15px auto;
      width: 168px;
      border-radius: 5px;
    }
  
    &__btns {
      margin-top: 10px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
  }