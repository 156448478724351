
.messages {
    width: 100%;
  
    &__container {
      position: relative;
      display: flex;
      flex-direction: column;
      background: white;
      border-radius: 16px;
      box-shadow: 0 2px 16px rgba(0, 0, 0, 0.0);
      height: 70vh;
    }
  
    &__prompt {
      position: absolute;
      top: 30%;
      z-index: 2;
      width: 100%;
    }
  
    &__list {
      flex: 1;
      position: relative;
    }
  
    &__scroller {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      padding: 16px;
      overflow-y: scroll;
    }
  
    &__message {
        align-self: flex-start;
        margin-top: 4px;
        padding: 8px 12px;
        background: #e9e6e6;
        border-radius: 16px;
        font-size: 14px;
        color: white;
    }
  
    &__message-me {
      display: flex;
      flex-direction: column;
      align-self: flex-end;
      align-items: flex-end;
    }

    &__text {
      color: black;
      display: flex;
      gap: 5px;
      align-items: center;
    }
  
    &__meta {
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: flex-start;
    }

    &__meta-me {
      justify-content: flex-start;
    }
  
    &__timestamp {
      font-size: 10px;
    }
  
    &__error {
      color: red;
    }
  
    &__coords {
      margin: 5px auto;
    }
  
    &__marker {
      width: 100%;
      text-align: center;
    }
  
    &__form {
      @extend .flex-row;
      gap: 5px;
      height: 100%;
      padding: 0 5px;
    }
  
    &__input {
      width: 100%;
      height: 32px;
      padding: 8px 16px;
      border: 1px solid #ddd;
      border-radius: 16px;
      outline: none;
    }
  
    &__input:focus {
      border: 1px solid #f19e38;
    }
  
    &__i {
      cursor: pointer;
      color: $base-color;
    }

    &__client-chat {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      height: 100%;
    }

    &__client-chat-container {
      height: calc(100% - 60px);
    }
  }

.client-chat {
  background: #f9f9f9;
}