
.service {

    &__container {
        padding: 15px 10px 15px 0;
        cursor: pointer;
        max-width: 400px;
    }

    &__img {
        max-width: 400px;
    }

    &__image {
        width: 100%;
        height: 205px;
        object-fit:cover;
        border-radius: 8px 8px 0 0;
    }

    &__text {
        background-color: rgba(118, 195, 212, 0.23);
        border-radius: 0 0 8px 8px;
    }

    &__name {
        margin: 4px 0;
        min-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
        font-size: 13px;
    }

    &__descr {
        font-size: 12px;
        font-weight: 400;
        margin: 4px 0;
        color: rgb(83, 81, 81);
        min-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}