
.nav {
  // background: white;
  // position: sticky;
  // top: 0;
  // box-shadow: 0 5px 8px gray;
  // padding: 0 1.5%;
  // z-index: 3;

  // &__container {
  //   @extend .flex-row;
  //   justify-content: center;
  //   padding: 5px 0;
  // }

  // &__logo-container {
  //   cursor: pointer;
  //   font-family: 'Poppins', sans-serif;
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  // }

  &__profile {
    @extend .flex-row;
  }

  &__image {
    width: 30px;
    cursor: pointer;
  }

  &__img {
    width: 100%;
    border-radius: 50%;
    display: block;
  }

  &__btns {
    @extend .flex-row;
    gap: 4px;
  }
// 
  &__link {
    color: black;
    cursor: pointer;
    height: 40px;
    line-height: 30px;
    font-weight: 600;
  }

  &__link:hover {
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 5px;
    color: gray;
  }

  &__selected {
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 5px;
    color: gray;
  }

  &__logout {
    margin-right: 2.5%;
    display: none;
  }

  &__active {
    border-bottom: 2px solid grey;
  }

  // &__sub {
  //   display: none;
  // }

  &__submenu {
    position: absolute;
    z-index: 3;
    padding: 5px 10px;
    border: 1px solid rgba(28, 34, 56, 1);
    border-radius: 5px;
    font-size: small;
    font-weight: bold;
    width: 160px;
    text-align: center;
    margin-left: -80px;
  }

  &__submenu::after{
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background: white;
    transform: rotate(45deg);
    top: -5px;
    margin-left: -5px;
    border-left: 0.1px solid black;
    border-top: 0.1px solid black;
  }

  &__auth-container {
    display: none;
  }
}

.nm {
  display: none;
}

.nm__tablet {
  display: none;
}

.submenu-text {
  text-align: left;
  cursor: pointer;
  padding: 10px 0;
  margin: 2px auto;
}

.submenu-text:hover {
  background: rgb(230, 226, 226);
}

@media screen and (max-width: 750px){
  .nav {
    &__dropdown-wrapper {
      position: absolute;
      z-index: 1;
      background: white;
      border: none;
      width: 100vw;
      height: 0;
      overflow: hidden;
      transition: height 0.2s ease-in;
    }
  
    &__dropdown {
      border: none;
      height: calc(100vh - 30px);
      overflow-y: scroll;
      overflow-x: hidden;
    }

    &__link {
      font-weight: bold;
      display: block !important;
    }
  
    &__link:hover {
      color: gray;
    }
  }
}
