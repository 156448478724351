
.slideshow {
  height: 400px;
  width: 90vw;
  max-width: 600px;
  position: relative;
  display: flex;
  overflow: hidden;
  border-bottom: 1px solid black;

  &__static {
    height: 300px;
    width: 100%;
  }

  &__btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    top: 40%;
    width: 100%;
  }

  &__feature {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    opacity: 0;
    transition: transform 1s ease-in;
    text-align: center;
  }

  &__icon {
    text-align: center;
  }

  &__active {
    opacity: 1;
    transform: translateX(0);
  }

  &__last {
    transform: translateX(-100%);
  }

  &__next {
    transform: translateX(100%);
  }
}

  .imgslider {
    height: 150px;
    width: 90vw;
    max-width: 600px;
    position: relative;
    display: flex;
    overflow: hidden;

    &__static {
      height: 300px;
      width: 100%;
    }

    &__btns {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: absolute;
      top: 40%;
      width: 100%;
    }

    &__feature {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: auto;
      opacity: 0;
      transition: transform 1s ease-in
    }

    &__icon {
      text-align: center;
    }

    &__active {
      opacity: 1;
      transform: translateX(0);
      text-align: center;
    }

    &__last {
      transform: translateX(-100%);
    }

    &__next {
      transform: translateX(100%);
    }
  }


@media screen and (min-width: 560px) {
  //nav
  .nav {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__btns {
      display: none;
    }

    &__hamburger {
      display: none;
    }

    &__dropdown-wrapper {
      height: auto !important;
      // display: inline-block;
      display: none;
      // position: static;
      // width: 180px;
      // margin-right: 2.5%;
      // background: transparent;
    }

    // &__dropdown {
    //   display: flex;
    //   flex-direction: row;
    //   justify-content: flex-end;
    //   margin: auto;
    //   height: auto;
    //   overflow: hidden;
    // }

    // &__link {
    //   display: inline-block;
    //   padding: 4px 0;
    //   margin: 0 2px;
    // }

    &__logout {
      display: block;
    }

    &__active {
      border-bottom: 2px solid white;
    }

    .link-black {
      color: black;
    }


    .nm {

      &__tablet {
        display: inline-block;
        margin-right: 2.5%;
      }
  }

    .nav {
      &__link {
        padding: 5px 0;
      }
    }
  }

  .disable, .link-icon {
    display: none !important;
  }


// form
  .form {
    width: 80%;
  }

  .signup-form {
    padding: 50px;
    margin: auto;
  }
}

@media screen and (min-width: 650px) {

  .detail {
    &__slider {
      display: none;
    }

    &__imgviewer {
      display: block;
    }
  }

  .booking {
    &__create {
      display: flex;
      justify-content: space-between;
    }

    &__header {
      margin-top: 20px;
      text-align: left;
    }

    &__form {
      background: #f9f9f9;
      padding: 10px 20px;
    }

    &__image {
      display: block;
    }
  }

  .login {
    &__content {
      width: 50%;
    }
  }

  // footer
  .footer {
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
  }

  .language {
    margin-top: 50px;
  }
  .copy-right {
    grid-column: 1 / span 5;
    text-align: center;
  }

  .socials {
    align-self: flex-start;
    margin-top: 40px;
  }
}

@media screen and (min-width: 750px){
    
  // bookings & services views
  .bookings, .services {
    &__root {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-top: 80px;
    }
  }

  .services__container {
    height: 1184px;
  }

  .imodal {
    &__btns {
      flex-direction: row;
      justify-content: space-evenly;
    }
  }

  .services {
    &__headings {
      width: 371px;
    }

    &__features {
      grid-template-columns: repeat(3, 1fr);
      align-items: flex-start;
    }

    &__vid {
      width: 60%;
      margin: auto;
      display: block;
    }
  }

  .form {
    width: 100%;

    &__h1 {
      font-weight: bolder;
      font-size: 50px;
    }
  }

  .rdsd-form {
    height: 980px !important;
    overflow-y: scroll;
  }

  // footer
  .footer {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-content: space-between;
  }
}

@media screen and (min-width: 850px) {
  // .header {
  //   background: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.5)), url('../views/images/race-car.webp') center/cover;
  // }

  .signup__root {
    height: 80vh;
    width: 80%;
    border-radius: 10px;
  }

  .signup-form {
    background-color: white;
    width: 600px;
    height: 400px;
    overflow: scroll;
    padding: 25px 50px;
  }

  .fwicon {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
  }

  .slider-remove {
    display: none;
  }

  .auth-container {
    display: block;
  }

  .nav__sub {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .nav__container {
    justify-content: space-between;
  }

  .nav__auth-container {
    display: block;
  }

  .nm {
    display: flex;
    gap: 5px;
    height: auto !important;
  }

  .header {
    width: 95%;
  }

  .detail {
    width: 80%;
    margin: auto;

    &__logo-container {
      text-align: center;
      padding-top: 50px;
    }

    &__logo {
      width: 250px;
      border-radius: 50%;
      border: 1px solid black;
    }

    &__video-container {
      height: auto;
    }
  }

  .booking {

    &__create {
      justify-content: space-around;
    }

    &__header {
      max-width: 490px;
    }

    &__form-item {
      flex-direction: row;
      justify-content: space-between;
    }

    &__input {
      width: 60%;
    }

    &__form {
      // max-width: 740px;
      width: 60%;
      margin: auto;
    }

    &__btn-container {
      text-align: center;
    }
  }

  .messages {
    &__client-chat {
      position: relative;
      height: 90%;
    }
  }

  .dropdown {
    display: none;
  }
}

@media screen and (min-width: 900px){
    // card
    .card {
      display: grid;
      grid-template-columns: repeat(2,1fr);
      align-items: center;
      gap: 50px;
      border-bottom: 1px solid black;
      padding-bottom: 50px;

      &__img {
        height: 60vh;
        object-fit: cover;
      }

      &__h {
        padding: 0 0 20px;
      }

      &__text {
        text-align: justify;
      }
    }
  // features with image
    .fwimage {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: flex-start;
      width: 90%;
      margin: 30px auto 60px;
      border-bottom: 1px solid black;
      padding-bottom: 18px;

      &__img {
        height: 30vh;
        margin-bottom: 60px;
        object-fit: contain;
        transition: transform 0.2s ease-in;
      }

      &__img:hover {
        transform: scale(1.05);
        }
      
        &__i {
          margin: 0;
          }

      &__p {
        margin-bottom: 20px;
        height: 40px;
      }

      &__feature {
        border: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0;
        margin-bottom: 20px;
      }
    }

    .slideshow {
      display: none;
    }

  // sign up
    .signup {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      &__element {
        width: 100%;
      }
    }
}
